import {AlertService} from "../services/AlertService";
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      code:{},
      selectedId:null,
      deficiencyDetail:{},
    }
  },
  computed:{
    ...mapGetters(['toEdit','deficiency','pscReport']),
  },
  methods: {
    ...mapActions(['updatePscInfo','renderDeficiencyAttachments']),
    getEmitString(key = 'general') {
      let emit = "";
      switch (key.toLowerCase()) {
        case 'general':
          emit = 'cancelDetail';
          break;
        case 'cause':
          emit = 'cancelCause';
          break;
        case 'preventative':
          emit = 'cancelPreventive';
          break;
        case 'corrective':
          emit = 'cancelCorrective';
          break;

        default:
          emit = 'cancelDetail'
          break
      }
      return emit
    },

    async updateDetail(fields,key='general') {
      if (await AlertService.confirmUpdateAlert()) {
        await this.updateDeficiency(fields,key);
      }
    },
    async cancelUpdate(){
      if(await AlertService.cancelAlert()){
        this.resetDeficiency({id:this.deficiency.id})
      }
    },
    async updateDeficiency(formData, key) {

      let customObj = {};
      if (key === 'general') {
        ['cause', 'preventative', 'corrective'].forEach(item => {
          delete formData[item]
        })
        customObj.deficiency = formData;
      } else {
        customObj = {id: this.$route.params.id, deficiency: {id: formData['id']}}
        customObj.deficiency[key] = formData[key];
      }
      customObj = {id: this.$route.params.id, ...customObj}
      const response = await this.updatePscInfo(customObj);
      if (response) {
        AlertService.successAlert('Psc Deficiency updated successfully', 'UPDATE DEFICIENCY');
        this.resetDeficiency({id:formData['id']})
      }
    },

    editDetail() {
      if(this.$route.name==='PscDeficiencyDetail'){
        this.code=this.deficiency.code ?? {}
      }
      this.$store.commit('SET_TO_EDIT',true)
    },


    async showDeficiency(deficiency){
      this.selectedId = deficiency.id
      let deficiencyParams={
        id:this.$route.params.id,
        deficiency:deficiency
      }
      await this.renderDeficiencyAttachments(deficiencyParams)
      localStorage.setItem('currentDeficiency',JSON.stringify(deficiency))
      this.$store.commit('SET_TO_EDIT', false)
      this.$store.commit('SET_DEFICIENCY',JSON.parse(localStorage.getItem('currentDeficiency')))
      this.deficiencyDetail=Object.assign({},this.deficiency)
    },

    resetDeficiency(event) {
      const jsonParsed = JSON.parse(localStorage.getItem('currentDeficiency'));
      if (jsonParsed.id == event.id) {
        let pscObj = this.pscGet('psc_deficiencies');
        pscObj = pscObj.find(obj => obj.id == event.id)
        localStorage.setItem('currentDeficiency', JSON.stringify(pscObj))
      }
      const deficiency=JSON.parse(localStorage.getItem('currentDeficiency'))
      this.$store.commit('SET_TO_EDIT', false)
      this.$store.commit('SET_DEFICIENCY',deficiency)
      this.deficiencyDetail=deficiency
    },

    preventRouteChange(event) {
      if (!this.toEdit) return
      event.preventDefault()
      event.returnValue = ""
    },
  },

  async beforeRouteLeave(to,from,next){
    if (this.toEdit) {
      if (await AlertService.cancelAlert()) {
          this.resetDeficiency({id:this.deficiency.id})
      }
      else {
        return;
      }
    }
    next();
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventRouteChange)
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventRouteChange);
    })
  }


}
